@import "@/assets/scss/style.scss";

.mt-20 {
    margin-top: 20px;
}
.mb-10 {
    margin-bottom: 10px;
}
.wrapper {
    position: relative;
    border: 1px solid $grey-4;
    background-color: $white-two;
    border-radius: 10px;
    padding: 24px;
    margin: 40px;

    &::v-deep {
        &.isLoading {
            opacity: 0.6;
        }

        .ant-spin {
            display: flex;
            position: absolute;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 50;
        }
    }
}
